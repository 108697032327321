
<script>
export default {
 async mounted() {
     await window.localStorage.clear();
     await this.$router.push("/"); 
},
}
</script>
<style lang="scss" scoped>
.cardHome {
  transition-duration: 50ms;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
 
  &:hover {
    background-color: #002750;
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    .img {
     color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.cardAdmin {
  transition-duration: 50ms;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
 
  &:hover {
    background-color: #ffca00;
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    .img {
     color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
